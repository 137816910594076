<template>
    <div class="d-flex h-100" style="top: 59px !important;">
        <side-bar :templates="templates" :campaigns="campaigns" :leads="leads" :identities="identities"
            :editable-node="editableNode" :nodesData="nodesData" @dragStartNode="dragStartNode"
            @fetchTemplates="fetchTemplates" @fetchCampaigns="fetchCampaigns" @fetchLeads="fetchLeads"
            @fetchIdentities="fetchIdentities" @updateCampaignNodeData="updateCampaignNodeData"
            @handleAddIdentity="handleAddIdentity" @addNodeToEditor="addNodeToDrawflow" />
        <div id="drawflow-container" class="flex-grow-1 position-relative bg-light">
            <div class="btn-group position-absolute" style="top: 10px; right: 10px; z-index: 1000;">
                <button @click="saveNodes" id="save-button" class="btn btn-primary">Save</button>
                <button @click="clearNodes" id="clear-button" class="btn btn-danger">Clear</button>
            </div>
            <div id="drawflow" ref="drawflowContainer" class="h-100 border rounded"></div>
            <div class="zoom-controls position-absolute">
                <button @click="zoomIn" class="btn btn-secondary"><i class="bi bi-zoom-in"></i></button>
                <button @click="zoomOut" class="btn btn-secondary"><i class="bi bi-zoom-out"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
import { createApp, h } from 'vue';
import Drawflow from "drawflow";
import APIService from "@/Services/ApiService/apiService";
import SideBar from "./FlowSideBar.vue";
import { eventBus } from '@/eventBus';
/* eslint-disable */
import CampaignNodeContent from './CampaignNode/CampaignNodeContent.vue';

class DrawflowOverride extends Drawflow {
    removeNodeById(id) {
        const nodeId = `node-${id}`;
        this.removeNodeId(nodeId);
    }
}

export default {
    name: "DrawflowComponent",
    components: {
        SideBar,
        CampaignNodeContent
    },
    data() {
        return {
            nodesData: [
                { name: "Template", content: "Template content", x: 50, y: 50, label: "<div><b>Template</b></div>", icon: "bi bi-columns" },
                { name: "Leads", content: "Leads content", x: 50, y: 150, label: "<div><b>Leads</b></div>", icon: "bi bi-award-fill" },
                { name: "Campaign", content: "Campaign", x: 50, y: 250, label: "<div><b>Campaign</b></div>", icon: "bi bi-activity" },
                { name: "Select Identity", content: "Select Identity", x: 50, y: 350, label: "<div><b>Execute</b></div>", icon: "bi bi-hand-index-thumb" },
            ],
            editableNode: null,
            templates: [],
            leads: [],
            identities: [],
            campaigns: [],
            zoom: 1,
            friendlyName: '',
        };
    },
    mounted() {
        document.addEventListener('edit-node', this.editNode);
        document.addEventListener('click', this.hideEditButton);
        this.initDrawflow();
        this.fetchData();
        this.bindNodeHoverEvents();
    },
    beforeUnmount() {
        document.removeEventListener('edit-node', this.editNode);
        document.removeEventListener('click', this.hideEditButton);
    },
    methods: {
        async fetchData() {
            this.$store.state.isLoading = true;
            try {
                await Promise.all([this.fetchTemplates(), this.fetchCampaigns(), this.fetchLeads(), this.fetchIdentities()]);
            } catch (error) {
                console.error("Failed to fetch all data:", error);
            } finally {
                this.$store.state.isLoading = false;
            }
        },
        async fetchCampaigns(paginator = 0, active = 1, progress = 0, pc = 0, comp = 1, terminated = 0) {
            if (isNaN(paginator)) paginator = 1;
            this.$store.state.isLoading = true;
            try {
                const campaignsResponse = await APIService.getCampaigns(paginator, active, progress, pc, comp, terminated);
                this.campaigns = campaignsResponse.data.campaign_details;

                console.log("Campaigns data", this.campaigns);
            } catch (error) {
                console.error("Failed to fetch campaigns:", error);
                console.error("Error response:", error.response);
            } finally {
                this.$store.state.isLoading = false;
            }
        },
        async fetchTemplates() {
            this.$store.state.isLoading = true;
            try {
                const templatesResponse = await APIService.getTemplates(-1);
                this.templates = Object.values(templatesResponse.data.All_templates);
                console.log("Templates data", this.templates);
            } catch (error) {
                console.error("Failed to fetch templates:", error);
            }
            finally {
                this.$store.state.isLoading = false;
            }
        },
        async fetchLeads() {
            this.$store.state.isLoading = true;
            try {
                const leadsResponse = await APIService.getCustomerGroups();
                this.leads = leadsResponse.data;
                console.log("Leads data", this.leads);
            } catch (error) {
                console.error("Failed to fetch leads:", error);
            }
            finally {
                this.$store.state.isLoading = false;
            }
        },
        async fetchIdentities() {
            this.$store.state.isLoading = true;
            try {
                const identitiesResponse = await APIService.identityManagement();
                if (identitiesResponse.data && typeof identitiesResponse.data === 'object' && !Array.isArray(identitiesResponse.data)) {
                    this.identities = Object.values(identitiesResponse.data);
                } else {
                    console.error('Unexpected identities structure:', identitiesResponse.data);
                    this.identities = [];
                }
                console.log("Identities data:", this.identities);
            } catch (error) {
                console.error("Failed to fetch identities:", error);
                this.identities = [];
            } finally {
                this.$store.state.isLoading = false;
            }
        },
        filterNodes(type) {
            return this.nodesData.filter(node => node.name === type);
        },
        updateFriendlyName(email) {
            const selectedIdentity = this.identities.find(identity => identity.email === email);
            this.friendlyName = selectedIdentity ? selectedIdentity.friendlyName : '';
        },
        dragStartNode(event, nodeData) {
            console.log("Drag started:", nodeData);
            event.dataTransfer.setData("application/json", JSON.stringify(nodeData));
        },
        initDrawflow() {
            const container = this.$refs.drawflowContainer;
            this.editor = new DrawflowOverride(container);
            this.editor.start();
            this.editor.reroute = true;
            this.editor.editor_mode = "edit";
            container.addEventListener("drop", this.dropNode);
            container.addEventListener("dragover", event => event.preventDefault());
            this.editor.on("connectionCreated", this.validateConnection);
            this.editor.on("connectionRemoved", this.handleConnectionRemoved);
        },
        dropNode(event) {
            event.preventDefault();
            console.log("Node dropped on Drawflow canvas.");
            const rect = this.$refs.drawflowContainer.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            const nodeDataString = event.dataTransfer.getData("application/json");
            let nodeData;
            try {
                nodeData = JSON.parse(nodeDataString);
                console.log("Node data parsed from drop event:", nodeData);

                if (nodeData && nodeData.type) {
                    this.addNodeToDrawflow(nodeData, x, y);
                } else {
                    console.error("Invalid node data structure:", nodeData);
                }
            } catch (error) {
                console.error("Error parsing node data:", error);
            }
        },
        removeNode(nodeId) {
            const node = this.editor.getNodeFromId(nodeId);
            if (node) {
                this.editor.removeNodeId(`node-${nodeId}`);
            } else {
                console.error(`Node with ID ${nodeId} not found or already removed.`);
            }
        },

        addNodeToDrawflow(nodeData) {
            const rect = this.$refs.drawflowContainer.getBoundingClientRect();
            const x = (rect.width / 2) - 100;
            const y = (rect.height / 2) - 50;

            console.log("Adding node to Drawflow:", nodeData, "at position:", x, y);

            this.addNodeToDrawflowInternal(nodeData, x, y);
        },
        addNodeToDrawflowInternal(nodeData, x, y) {
            // Function to create the node template with a cancel button and a heading for the node type
            const createNodeTemplate = (content = '', nodeType = '', nodeId, inputs = 0, outputs = 0) => `
   <div class="node-content ${nodeType.toLowerCase() === 'campaign' ? 'campaign-node' : nodeType.toLowerCase() === 'identity' ? 'identity-node' : 'default-node'}" id="node-${nodeId}">
         <div class="node-header">
            ${nodeType}
         </div>
         <div class="cancel-button">
            <i class="bi bi-x" style="font-size: x-large !important;"></i>
         </div>
         <div>${content}</div>
         ${nodeType.toLowerCase() !== 'identity' ? `
         <div class="arrow arrow-right" data-node-id="${nodeId}" data-node-type="${nodeType}" data-inputs="${inputs}" data-outputs="${outputs}">
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 5.829l6.171 6.171-6.171 6.171v-3.171h-13v-6h13v-3.171zm-2-4.829v6h-13v10h13v6l11-11-11-11z"/></svg>
            <span class="tooltip"></span>
         </div>` : ''}
         ${nodeType.toLowerCase() === 'campaign' || nodeType.toLowerCase() === 'identity' ? `
         <div class="arrow arrow-right-left" data-node-id="${nodeId}" data-node-type="${nodeType}" data-inputs="${inputs}" data-outputs="${outputs}">
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 5.829l6.171 6.171-6.171 6.171v-3.171h-13v-6h13v-3.171zm-2-4.829v6h-13v10h13v6l11-11-11-11z"/></svg>
            <span class="tooltip"></span>
         </div>` : ''}
         </div>`;
            let nodeTemplate = '';
            const nodeTypeHeading = nodeData.type.toUpperCase();

            if (nodeData.type === 'campaign') {
                nodeData.inputs = 1;
                nodeData.outputs = 1;
                nodeTemplate = createNodeTemplate(`<div id="vue-node-placeholder" style="color:gray"></div>`, nodeTypeHeading, nodeData.id, nodeData.inputs, nodeData.outputs);
            } else if (nodeData.type === 'template' || nodeData.type === 'lead') {
                nodeData.inputs = 0;
                nodeData.outputs = 1;
                nodeTemplate = createNodeTemplate(`<b style="color:gray">${nodeData.name}</b>`, nodeTypeHeading, nodeData.id, nodeData.inputs, nodeData.outputs);
            } else if (nodeData.type === 'identity') {
                nodeData.inputs = 1;
                nodeData.outputs = 0;
                nodeTemplate = createNodeTemplate(`<b style="color:gray">${nodeData.name}</b>`, nodeTypeHeading, nodeData.id, nodeData.inputs, nodeData.outputs);
            }

            const nodeId = this.editor.addNode(
                nodeData.name,
                nodeData.inputs,
                nodeData.outputs,
                x, y,
                nodeData.name,
                nodeData,
                nodeTemplate
            );

            // Ensure the DOM is ready before mounting Vue components
            this.$nextTick(() => {
                this.mountNodeContent(nodeId, nodeData);

                const nodeElement = document.getElementById(`node-${nodeId}`);
                if (nodeElement) {
                    const arrowElementRight = nodeElement.querySelector('.arrow-right');
                    if (arrowElementRight) {
                        const tooltipElementRight = arrowElementRight.querySelector('.tooltip');
                        arrowElementRight.addEventListener('mouseover', () => {
                            const nodeType = arrowElementRight.getAttribute('data-node-type');

                            console.log(`Node Type: ${nodeType}`);

                            if (tooltipElementRight && nodeType) {
                                let suggestion = '';

                                if (nodeType.toLowerCase() === 'campaign') {
                                    const allNodes = Object.values(this.editor.export().drawflow.Home.data);
                                    const campaignNode = allNodes.find(node => node.data.type.toLowerCase() === 'campaign');

                                    if (!campaignNode) {
                                        console.error('Campaign node not found.');
                                        arrowElementRight.style.display = 'none'; // Hide the arrow
                                        return;
                                    }

                                    const connectedNodes = campaignNode.outputs?.output_1?.connections || [];
                                    const isConnectedToIdentity = connectedNodes.some(conn => {
                                        const connectedNode = this.editor.getNodeFromId(conn.node);
                                        return connectedNode?.data?.type === 'identity';
                                    });

                                    if (isConnectedToIdentity) {
                                        arrowElementRight.style.display = 'none';
                                        tooltipElementRight.style.display = 'none';
                                        return;
                                    }

                                    suggestion = 'Identity Output';

                                } else if (nodeType.toLowerCase() === 'lead') {
                                    suggestion = 'Campaigns Output';

                                } else if (nodeType.toLowerCase() === 'template') {
                                    suggestion = 'Campaigns Output';

                                } else {

                                    return;
                                }

                                if (suggestion) {
                                    tooltipElementRight.textContent = suggestion;
                                    tooltipElementRight.style.display = 'block';
                                } else {
                                    tooltipElementRight.style.display = 'none';
                                }
                            } else {
                                console.error('Tooltip or node type is missing.');
                                arrowElementRight.style.display = 'none';
                            }
                        });
                    }
                    const arrowElementRightLeft = nodeElement.querySelector('.arrow-right-left');
                    if (arrowElementRightLeft) {
                        const tooltipElementRightLeft = arrowElementRightLeft.querySelector('.tooltip');
                        arrowElementRightLeft.addEventListener('mouseover', () => {
                            const nodeType = arrowElementRightLeft.getAttribute('data-node-type');

                            console.log(`Node Type: ${nodeType}`);

                            if (tooltipElementRightLeft && nodeType) {
                                let suggestion = '';

                                if (nodeType.toLowerCase() === 'campaign') {
                                    const allNodes = Object.values(this.editor.export().drawflow.Home.data);
                                    const campaignNode = allNodes.find(node => node.data.type.toLowerCase() === 'campaign');

                                    if (!campaignNode) {
                                        console.error('Campaign node not found.');
                                        arrowElementRightLeft.style.display = 'none'; // Hide the arrow
                                        return;
                                    }

                                    const connectedNodes = campaignNode.inputs?.input_1?.connections || [];
                                    const isConnectedToTemplate = connectedNodes.some(conn => {
                                        const connectedNode = this.editor.getNodeFromId(conn.node);
                                        return connectedNode?.data?.type === 'template';
                                    });
                                    const isConnectedToLead = connectedNodes.some(conn => {
                                        const connectedNode = this.editor.getNodeFromId(conn.node);
                                        return connectedNode?.data?.type === 'lead';
                                    });

                                    if (isConnectedToTemplate && isConnectedToLead) {
                                        // If both template and lead are connected, hide the arrow-right-left entirely
                                        arrowElementRightLeft.style.display = 'none';
                                        return;
                                    }

                                    if (!isConnectedToTemplate && !isConnectedToLead) {
                                        suggestion = 'Template & Lead Input';
                                    } else if (!isConnectedToTemplate) {
                                        suggestion = 'Template Input';
                                    } else if (!isConnectedToLead) {
                                        suggestion = 'Lead Input';
                                    }

                                } else if (nodeType.toLowerCase() === 'identity') {
                                    const allNodes = Object.values(this.editor.export().drawflow.Home.data);
                                    const identityNode = allNodes.find(node => node.data.type.toLowerCase() === 'identity');

                                    if (!identityNode) {
                                        console.error('Identity node not found.');
                                        arrowElementRightLeft.style.display = 'none';
                                        return;
                                    }

                                    const connectedNodes = identityNode.inputs?.input_1?.connections || [];
                                    const isConnectedToCampaign = connectedNodes.some(conn => {
                                        const connectedNode = this.editor.getNodeFromId(conn.node);
                                        return connectedNode?.data?.type === 'campaign';
                                    });

                                    if (!isConnectedToCampaign) {
                                        suggestion = 'Campaign Input';
                                    }
                                }

                                if (suggestion) {
                                    tooltipElementRightLeft.textContent = suggestion;
                                    tooltipElementRightLeft.style.display = 'block';
                                } else {
                                    tooltipElementRightLeft.style.display = 'none';
                                }
                            } else {
                                console.error('Tooltip or node type is missing.');
                                arrowElementRightLeft.style.display = 'none'; // Hide the arrow
                            }
                        });
                    }
                }

                eventBus.emit('clear-campaign-node-fields');
            });
        },
        mountNodeContent(nodeId, nodeData) {
            let nodeElement;

            if (nodeData.type === 'campaign') {
                nodeElement = document.getElementById('vue-node-placeholder');
                if (nodeElement) {
                    nodeElement.id = `vue-node-${nodeId}`;
                    nodeElement = nodeElement.closest('.node-content');
                }
            } else {
                nodeElement = document.getElementById(`node-${nodeId}`);
                if (nodeElement) {
                    nodeElement = nodeElement.querySelector('.node-content');
                }
            }

            if (nodeElement && nodeElement.parentNode) {
                const parentElement = nodeElement.parentNode;

                // Attach the click event listener to the cancel button
                const cancelButton = parentElement.querySelector('.cancel-button');
                if (cancelButton) {
                    cancelButton.addEventListener('click', () => {
                        this.removeNode(nodeId);
                        eventBus.emit('clear-campaign-form');
                    });
                } else {
                    console.error("Cancel button element not found.");
                }

                if (nodeData.type === 'campaign') {
                    console.log("Mounting CampaignNodeContent to Drawflow node ID:", nodeId);
                    const app = createApp({
                        render() {
                            return h(CampaignNodeContent, {
                                name: nodeData.name,
                                subject: nodeData.subject,
                                startDate: nodeData.startDate,
                                endDate: nodeData.endDate,
                                nodeId: nodeId,
                                isEditable: nodeData.isEditable,
                                hideSubject: nodeData.hideSubject
                            });
                        }
                    });
                    app.mount(nodeElement.querySelector(`#vue-node-${nodeId}`));
                    console.log("Vue component mounted successfully.");
                }
            } else {
                console.error("Failed to find the placeholder element or parent node for mounting Vue component.");
            }
        },
        updateCampaignNodeData(updatedNodeData) {
            const nodeId = updatedNodeData.id;
            this.updateNodeData(nodeId, updatedNodeData);
        },
        emitEditNode(nodeData) {
            const node = this.editor.getNodeFromId(nodeData.id);
            if (node) {
                eventBus.emit('edit-node', node);
            } else {
                console.error(`Node with ID ${node} not found in Drawflow.`);
            }
        },
        handleEditNode(nodeData) {
            eventBus.emit('campaign-node-edit', nodeData);
        },
        hideEditButton(event) {
            const editButtons = document.querySelectorAll('.edit-button');
            editButtons.forEach(button => {
                if (!button.contains(event.target)) {
                    button.style.display = 'none';
                }
            });
        },
        clearNodes() {
            this.editor.clear();
            this.$nextTick(() => {
                this.editor.import({ drawflow: { Home: { data: {} } } });
            });
        },
        async saveNodes() {
            const drawflowData = this.editor.export();
            console.log('Drawflow data:', drawflowData.drawflow.Home.data);

            const campaignNodes = this.getCampaignNodes(drawflowData);
            if (campaignNodes.length === 0) {
                this.showToast({
                    text: 'No campaign nodes found. Please provide at least one campaign.',
                    type: 'warning',
                });
                return;
            }

            const apiCalls = this.createCampaignApiCalls(campaignNodes);
            await this.executeApiCalls(apiCalls);
        },
        getCampaignNodes(drawflowData) {
            return Object.values(drawflowData.drawflow.Home.data).filter(node => node.data.type === 'campaign');
        },
        createCampaignApiCalls(campaignNodes) {
            const apiCalls = [];
            const processedNodes = new Set();

            campaignNodes.forEach(campaignNode => {
                if (processedNodes.has(campaignNode.id)) {
                    return;
                }
                processedNodes.add(campaignNode.id);

                const { templateNodeId, leadNodeId, emailNodeName } = this.getEffectiveNodeData(campaignNode);

                if (!templateNodeId || !leadNodeId) {
                    console.warn(`Skipping API call for node ${campaignNode.id} due to missing template or lead ID.`);
                    return;
                }

                // Add the API call for the parent campaign node
                apiCalls.push(this.createCampaignApiCall(campaignNode, templateNodeId, leadNodeId, emailNodeName));

                // Handle child campaign nodes, ensuring no duplicate calls
                if (Array.isArray(campaignNode.data.childIds) && campaignNode.data.childIds.length > 0) {
                    campaignNode.data.childIds.forEach(childId => {
                        const childNode = this.editor.getNodeFromId(childId);
                        if (childNode && !processedNodes.has(childNode.id)) {
                            processedNodes.add(childNode.id);
                            const { templateNodeId: childTemplateNodeId, leadNodeId: childLeadNodeId, emailNodeName: childEmailNodeName } = this.getEffectiveNodeData(childNode);

                            if (childTemplateNodeId && childLeadNodeId) {
                                apiCalls.push(this.createCampaignApiCall(childNode, childTemplateNodeId, childLeadNodeId, childEmailNodeName));
                            } else {
                                console.warn(`Skipping API call for child node ${childId} due to missing template or lead ID.`);
                            }
                        }
                    });
                }
            });

            return apiCalls;
        },

        // Helper method to get effective node data, inheriting from parent if necessary
        getEffectiveNodeData(node) {
            if (!node || !node.data) {
                console.warn('Invalid node or node data:', node);
                return { templateNodeId: null, leadNodeId: null, emailNodeName: null };
            }

            let { templateNodeId, leadNodeId, emailNodeName } = this.getConnectedNodes(node);

            // If template, lead, or email is not directly connected, inherit from parent
            if (!templateNodeId || !leadNodeId || !emailNodeName) {
                const parentNode = node.data.parentId ? this.editor.getNodeFromId(node.data.parentId) : null;
                if (parentNode) {
                    const parentData = this.getEffectiveNodeData(parentNode);
                    templateNodeId = templateNodeId || parentData.templateNodeId;
                    leadNodeId = leadNodeId || parentData.leadNodeId;
                    emailNodeName = emailNodeName || parentData.emailNodeName;
                }
            }

            return { templateNodeId, leadNodeId, emailNodeName };
        },
        getConnectedNodes(campaignNode) {
            let templateNodeId = campaignNode.data.templateNodeId;
            let leadNodeId = campaignNode.data.leadNodeId;
            let emailNodeName = campaignNode.data.emailNodeName;

            const inputConnections = campaignNode.inputs?.input_1?.connections || [];
            const outputConnections = campaignNode.outputs?.output_1?.connections || [];

            inputConnections.forEach(connection => {
                const connectedNode = this.editor.getNodeFromId(connection.node);
                if (connectedNode) {
                    if (connectedNode.data.type === 'template' && !templateNodeId) {
                        const matchingTemplate = this.templates.find(template => template.name === connectedNode.name);
                        if (matchingTemplate) {
                            templateNodeId = matchingTemplate.id;
                        }
                    } else if (connectedNode.data.type === 'lead' && !leadNodeId) {
                        const matchingLead = this.leads.find(lead => lead.name === connectedNode.name);
                        if (matchingLead) {
                            leadNodeId = matchingLead.id;
                        }
                    }
                }
            });

            outputConnections.forEach(connection => {
                const connectedNode = this.editor.getNodeFromId(connection.node);
                if (connectedNode && connectedNode.data.type === 'identity' && !emailNodeName) {
                    emailNodeName = connectedNode.data.name;
                }
            });

            return { templateNodeId, leadNodeId, emailNodeName };
        },
        createCampaignApiCall(campaignNode, templateNodeId, leadNodeId, emailNodeName) {
            this.updateFriendlyName(emailNodeName);
            const body = {
                name: campaignNode.data.name,
                subject: campaignNode.data.subject,
                tid: templateNodeId,
                sdate: campaignNode.data.startDate || new Date().toISOString().split('T')[0],
                edate: campaignNode.data.endDate || new Date().toISOString().split('T')[0],
                cgrp: leadNodeId,
                mailToUse: emailNodeName,
                friendlyName: this.friendlyName,
            };

            // Conditionally add parentNodeId or childNodeId based on the node's role
            // if (campaignNode.data.childIds?.length > 0) {
            //     body.childNodeId = campaignNode.data.childIds.join(",");
            // }
            if (campaignNode.data.parentId) {
                body.parentNodeId = campaignNode.data.parentId;
            }

            return APIService.createCampaigns(body)
                .then(response => ({ response, name: campaignNode.data.name }))
                .catch(error => ({ error, name: campaignNode.data.name }));
        },
        async executeApiCalls(apiCalls) {
            if (apiCalls.length === 0) return;
            this.$store.state.isLoading = true;

            try {
                const results = await Promise.all(apiCalls);

                const errors = results.filter(result => result.error);
                if (errors.length > 0) {

                    errors.forEach(({ error, name }) => {
                        const errorMessage = error.response?.data?.message || error.response?.data || error.message;
                        this.showToast({
                            text: `Error for campaign "${name}": ${errorMessage}`,
                            type: 'error',
                        });
                    });
                } else {
                    this.showToast({
                        text: "Campaign created successfully!",
                        type: 'success',
                    });
                    this.navigateTo();
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.response?.data || error.message;
                this.showToast(errorMessage);
            } finally {
                this.$store.state.isLoading = false;
            }
        },
        validateConnection(connection) {
            const { output_id, input_id } = connection;
            try {
                const outputNode = this.editor.getNodeFromId(output_id);
                const inputNode = this.editor.getNodeFromId(input_id);
                console.log("Connection created between:", outputNode, inputNode);

                if (!outputNode || !inputNode) {
                    this.showMessageAndRemoveConnection("Invalid connection: Node(s) not found.", connection);
                    return;
                }

                if (this.isInvalidConnectionPair(outputNode, inputNode) ||
                    this.isMultipleIdentityConnections(outputNode, inputNode) ||
                    this.isInvalidCampaignInputConnection(outputNode, inputNode)) {
                    this.removeConnectionImmediately(connection);
                } else if (outputNode.data.type === 'campaign' && inputNode.data.type === 'campaign') {
                    this.setParentChildRelation(outputNode, inputNode);
                }

                this.updateParentChildRelationship(inputNode);

            } catch (error) {
                console.error("Error during connection validation:", error.message);
            }
        },
        isInvalidConnectionPair(outputNode, inputNode) {
            const invalidConnectionPairs = [
                ['template', 'lead'],
                ['template', 'identity'],
                ['lead', 'identity'],
            ];

            if (invalidConnectionPairs.some(pair => (outputNode.data.type === pair[0] && inputNode.data.type === pair[1]) || (outputNode.data.type === pair[1] && inputNode.data.type === pair[0]))) {
                this.showToast({
                    text: `Invalid connection: ${outputNode.data.type} cannot be connected to ${inputNode.data.type}`,
                    type: 'error',
                });

                return true;
            }
            return false;
        },
        setParentChildRelation(parentNode, childNode) {
            console.log("Setting parent-child relationship between nodes:");
            childNode.data.parentId = parentNode.id;

            if (!parentNode.data.childIds) {
                parentNode.data.childIds = [];
            }
            parentNode.data.childIds.push(childNode.id);

            // Inherit the templateNodeId, leadNodeId, and emailNodeName from the parent if not directly connected
            if (!childNode.data.templateNodeId) {
                childNode.data.templateNodeId = parentNode.data.templateNodeId;
            }
            if (!childNode.data.leadNodeId) {
                childNode.data.leadNodeId = parentNode.data.leadNodeId;
            }
            if (!childNode.data.emailNodeName) {
                childNode.data.emailNodeName = parentNode.data.emailNodeName;
            }
            this.editor.updateNodeDataFromId(childNode.id, childNode.data);
            this.editor.updateNodeDataFromId(parentNode.id, parentNode.data);
            console.log("Parent-child relationship set successfully.");
        },
        getParentChildRelationships() {
            const nodes = this.editor.export().drawflow.Home.data;
            const relationships = {};

            Object.values(nodes).forEach(node => {
                if (node.data.parentId || node.data.childIds) {
                    relationships[node.id] = {
                        parentId: node.data.parentId || null,
                        childIds: node.data.childIds || []
                    };
                }
            });

            console.log("Parent-Child Relationships:", relationships);
            return relationships;
        },
        updateParentChildRelationship(node) {
            if (!node || node.data.type !== 'campaign') return;

            const childConnections = node.outputs?.output_1?.connections || [];
            childConnections.forEach(connection => {
                const childNode = this.editor.getNodeFromId(connection.node);
                if (childNode && childNode.data.type === 'campaign') {
                    this.setParentChildRelation(node, childNode);
                    this.updateParentChildRelationship(childNode);
                }
            });
        },
        isMultipleIdentityConnections(outputNode, inputNode) {
            if (outputNode.data.type === "campaign" && inputNode.data.type === "identity") {
                const outputConnections = outputNode.outputs?.output_1?.connections || [];
                const identityConnections = outputConnections.filter(conn => {
                    const connectedNode = this.editor.getNodeFromId(conn.node);
                    return connectedNode && connectedNode.data.type === "identity";
                });

                if (identityConnections.length > 1) {
                    this.showToast({
                        text: "Invalid connection: Campaign node output cannot be connected to more than one Identity node.",
                        type: 'error',
                    });
                    return true;
                }
            }
            return false;
        },

        isInvalidCampaignInputConnection(outputNode, inputNode) {
            if (inputNode.data.type === "campaign") {
                const inputConnections = inputNode.inputs?.input_1?.connections || [];
                const connectedNodes = inputConnections.map(conn => this.editor.getNodeFromId(conn.node));

                const templateConnections = connectedNodes.filter(node => node?.data?.type === "template").length;
                const leadConnections = connectedNodes.filter(node => node?.data?.type === "lead").length;

                if ((outputNode.data.type === "template" && templateConnections > 1) || (outputNode.data.type === "lead" && leadConnections > 1)) {
                    this.showToast({
                        text: `Invalid connection: ${inputNode.data.type} can only have one ${outputNode.data.type}`,
                        type: 'error',
                    });
                    return true;
                }
            }
            return false;
        },
        showMessageAndRemoveConnection(message, connection) {
            this.$store.state.toastMessage = message;
            this.removeConnectionImmediately(connection);
        },
        showToast(message) {
            const DEFAULT_AUTO_CLOSE = 2000;
            this.$store.state.toastMessage = '';
            setTimeout(() => {
                if (typeof message === 'object') {
                    this.$store.state.toastMessage = {
                        text: message.text || 'Default message',
                        type: message.type || 'default',
                        autoClose: message.autoClose || DEFAULT_AUTO_CLOSE,
                    };
                } else {
                    this.$store.state.toastMessage = {
                        text: message,
                        type: 'default',
                        autoClose: DEFAULT_AUTO_CLOSE,
                    };
                }
            }, 10);
        },

        removeConnectionImmediately(connection) {
            const { output_id, input_id } = connection;
            try {
                this.editor.removeSingleConnection(output_id, input_id, 'output_1', 'input_1');
                console.log("Connection successfully removed:", { output_id, input_id });
            } catch (error) {
                console.error("Error during connection removal:", error.message);
            }
        },

        handleConnectionRemoved(connection) {
            console.log("Connection removed:", connection);
        },
        navigateTo() {
            const query = Object.assign({}, this.$route.query);
            delete query.page;
            const previousPage = parseInt(this.$route.query.page) || 1;
            this.$router.push({ name: "campaigns", query: { ...query, page: previousPage } });
        },
        zoomIn() {
            this.zoom = this.zoom + 0.1;
            this.editor.zoom_in();
        },
        zoomOut() {
            this.zoom = this.zoom - 0.1;
            this.editor.zoom_out();
        },
        updateNodeData(nodeId, newData) {
            console.log("Updating node data with ID:", nodeId, "and new data:", newData);
            const node = this.editor.getNodeFromId(nodeId);
            if (node) {
                node.data = { ...node.data, ...newData };
                this.editor.updateNodeDataFromId(nodeId, node.data);
                this.refreshNodeContent(nodeId, newData);
            }
        },

        refreshNodeContent(nodeId, newData) {
            const nodeElement = document.getElementById(`vue-node-${nodeId}`);
            if (nodeElement) {
                const app = createApp({
                    render() {
                        return h(CampaignNodeContent, {
                            name: newData.name,
                            subject: newData.subject,
                            startDate: newData.startDate,
                            endDate: newData.endDate,
                            nodeId: nodeId,
                        });
                    }
                });
                app.mount(nodeElement);
            }
        },
        handleAddIdentity(newIdentity) {
            console.log(this.identities, Array.isArray(this.identities));
            if (Array.isArray(this.identities)) {
                this.identities.push(newIdentity);
            } else {
                console.error('Identities is not an array:', this.identities);
            }
        },
        bindNodeHoverEvents() {
            const nodes = document.querySelectorAll('.drawflow-node');
            nodes.forEach(node => {
                node.addEventListener('mouseover', () => {
                    node.classList.add('node-hover');
                });
                node.addEventListener('mouseleave', () => {
                    node.classList.remove('node-hover');
                });
            });
        },
    },
};
</script>
<style>
.node-content {
    position: relative;
}

.cancel-button {
    position: absolute;
    height: 30px;
    width: 30px;
    color: white;
    font-weight: 700;
    text-align: center;
    font-family: monospace;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid white !important;
    background-color: red;
    z-index: 4;
    right: -30px;
    top: -27px;
    display: block !important;
    opacity: 0;

}

.parent-node:hover .cancel-button {
    display: block !important;
    opacity: 1;
}

#drawflow-container {
    flex-grow: 1;
    position: relative;
    background-color: #f5f5f5;
}

#drawflow {
    height: 100%;
    overflow: hidden;
    background-size: 25px 25px;
    background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px), linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
}

.drawflow .drawflow-node {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    position: absolute !important;
    width: auto !important;
    min-height: 60px !important;
    border-radius: 8px !important;
    border: 2px solid #000 !important;
    color: #000 !important;
    background-color: #fff !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    z-index: 2 !important;
    padding: 10px 15px !important;
    font-family: Arial, sans-serif !important;
    font-size: 14px !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.drawflow .drawflow-node .node-title {
    font-weight: bold !important;
}

.drawflow .drawflow-node.selected {
    border-color: #007bff !important;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5) !important;

}

.drawflow .drawflow-node .input {
    background-color: white !important;
}

.parent-node .drawflow-delete {
    display: none !important;
    line-height: 25px !important;
    margin-top: 0px !important;
    background-color: red !important;
}

.drawflow-delete {
    margin-left: -15px;
    margin-top: 2px !important;
    background-color: red !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.zoom-controls {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.zoom-controls .btn {
    margin-bottom: 5px;
}

.node-header {
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    text-align: center;
    border-bottom: 2px solid;
}

.drawflow .drawflow-node .output {
    right: -7px !important;
}

.parent-node .arrow {
    position: absolute;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    opacity: 0;
    z-index: 5;
}

.parent-node:hover .arrow {
    opacity: 1;
    /* transform: scale(1.2); */
}

.arrow-right {
    right: -52px !important;
    top: 50% !important;
    transform: translateY(-50%);
}

.arrow-right-left {
    left: -52px !important;
    top: 50% !important;
    transform: translateY(-50%);
}

.campaign-node .arrow-right-left {
    left: -52px !important;
    top: 50% !important;
    transform: translateY(-50%);
}

.tooltip {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 3px !important;
    position: absolute;
    z-index: 10;
    left: 105%;
    top: 27px !important;
    font-size: 9px !important;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    white-space: nowrap !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: none !important;
    width: auto !important;
    min-width: auto !important;
}

.arrow-right:hover .tooltip,
.arrow-right-left:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateY(-50%) scale(1.1);
}

.bi-arrow-right {
    position: absolute !important;
}

.campaign-node .arrow-right {
    right: -52px !important;
    top: 50% !important;
    transform: translateY(-50%);
}
</style>
