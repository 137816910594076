<template>
    <div class="container-fluid pb-4">
        <div class="row rovlin-prod-page-header py-4 m-0">
            <div class="rovlin-prod-page-title">
               Tell us your issue, and we'll help you resolve it.
            </div>
        </div>
        <div class="row m-0">
            <form class="col-sm-12 col-md-6 custom-new-card p-3" @submit.prevent="onSubmit" style="max-height: 340px;">
                <!-- <div class="mb-3 row">
                    <label class="col-sm-3 col-form-label" for="f3_Email">Email Address</label>
                    <div class="col-sm-9">
                        <input id="f3_Email" class="form-control" v-model="form.email" type="email"
                            placeholder="email@example.com" required />
                    </div>
                </div> -->

                <div class="mb-3 row">
                    <label for="f3_Issue" class="col-sm-3 col-form-label">Issue type</label>
                    <div class="col-sm-9">
                        <select class="form-select" id="f3_Issue" v-model="form.issue_type" aria-label="Default select example">
                            <option selected disabled>--Select--</option>
                            <option value="billing">Billing</option>
                            <option value="technical">Technical</option>
                            <option value="subscription">Subscription</option>
                            <option value="general_question">General Question</option>
                        </select>
                    </div>
                </div>

                <div class="mb-3 row">
                    <label for="f3_Descritpion" class="col-sm-3 col-form-label">Description</label>
                    <div class="col-sm-9">
                        <textarea id="f3_Descritpion" class="form-control" v-model="form.description" placeholder="Enter description"
                            :rows="5" :max-rows="6" required>
                        </textarea>
                    </div>
                </div>

                <div class="d-flex justify-content-center">
                    <button type="submit" class=" btn rounded-4 btn-primary">
                        Submit
                    </button>
                </div>
            </form>
            <!-- <div class="col-sm-12 col-md-6 d-flex justify-content-center">
                <img :src="require('../../../assets/support.png')" class="img-fluid" style="max-width: 500px;"/>
            </div> -->
        </div>
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";
import { Auth } from "aws-amplify";
export default {
    name: "SupportComponent",
    data() {
        return {
            user: {},
            form: {
                issue_type: "",
                description: "",
            },
        };
    },
    computed: {},
    methods: {
        onSubmit() {
            const body = {
                email: this.user?.attributes?.email,
                issue_type: this.form.issue_type,
                description: this.form.description,
            };
            APIService.updateSupport(body)
                .then(() => {
                    this.$store.state.toastMessage =
                        "Query submit successfully";
                    this.form.issue_type = "";
                    this.form.description = "";
                })
                .catch((error) => {
                    const errorMessage = { ...error };
                    this.$store.state.toastMessage =
                        errorMessage.response.data.description ||
                        errorMessage.response.data;
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
    },
    created() {
        Auth.currentUserInfo().then((user) => {
            this.user = user;
        });
    },
};
</script>

<style scoped>
</style>
