<template>
    <div :class="{ 'authentication': !userLogin }">
        <authenticator :form-fields="formFields" :social-providers="['google']" :initial-state="initialState"
            v-if="rendered && !userLogin" :hideSignUp="!showSignUp">

            <!--***** :) Header Customization *****-->

            <template v-slot:header>
                <div style="text-align: center" class="py-4">
                    <img class="amplify-image" alt="Amplify logo" :src="logoPath" />
                </div>
            </template>

            <!--***** :) Sign in Customization *****-->

            <template v-slot:sign-in-footer>
                <div class="d-flex justify-content-around pt-2">
                    <span class="pointer rovlin-prod-cursor-pointer navigate-link"
                        @click='setAuthInitialState("resetPassword")'>Forgot Password?</span>
                    <span class="pointer rovlin-prod-cursor-pointer navigate-link" v-if="showSignUp"
                        @click='setAuthInitialState("signUp")'>Create Account</span>
                </div>
                <div class="mt-2 pt-2 pb-3 mx-5 border-top">
                    <div class="d-flex justify-content-center align-items-center" style="column-gap: 10px;">
                        <a href="https://rovlin.com/privacy"><span class="pointer rovlin-sub-footer-text">Privacy
                                Policy</span></a>
                        <span class="rovlin-sub-footer-text">.</span>
                        <a href="https://rovlin.com/terms"><span class="pointer rovlin-sub-footer-text"
                                @click='setAuthInitialState("signUp")'>Terms</span></a>
                    </div>
                    <div class="d-flex justify-content-center text-muted mt-1" style="font-size: 10px;"> Copyright © {{
                        currentYear }} Rovlin</div>
                </div>
            </template>


            <!-- **** After Auth Preview **** -->
            <!-- **** Sign Up Customization  :)**** -->

            <template v-slot:sign-up-footer>
                <div style="" class="d-flex justify-content-around pt-2">
                    <span class=" pointer rovlin-prod-cursor-pointer navigate-link"
                        @click='setAuthInitialState("signIn")'>Already have an Rovlin account? Log in</span>
                </div>
                <div class="mt-2 pt-2 pb-3 mx-5 border-top">
                    <div class="d-flex justify-content-center align-items-center" style="column-gap: 10px;">
                        <a href="https://rovlin.com/privacy"><span class=" pointer rovlin-sub-footer-text">Privacy
                                Policy</span></a>
                        <span class="rovlin-sub-footer-text">.</span>
                        <a href="https://rovlin.com/terms"><span class=" pointer rovlin-sub-footer-text"
                                @click='setAuthInitialState("signUp")'>Terms</span></a>
                    </div>
                    <div class="d-flex justify-content-center text-muted mt-1" style="font-size: 10px;"> Copyright © {{
                        currentYear }} Rovlin</div>
                </div>
            </template>

        </authenticator>
        <div v-else>
            <Layouts>
                <router-view />
            </Layouts>
        </div>
    </div>
</template>

<script>
import Logo from "./assets/logo.png";
import Layouts from "./components/layouts/Layouts";
import { Hub, Auth } from "aws-amplify";
import { toast } from "vue3-toastify";

export default {
    name: "App",
    components: {
        Layouts,
    },
    data() {
        return {
            showSignUp: process.env.VUE_APP_SIGN_UP === "true",
            currentYear: new Date().getFullYear(),
            rendered: true,
            initialState: "signIn",
            logoPath: Logo,
            userLogin: false,
            loadingSpinner: null,
            formFields: {
                signIn: {
                    username: {
                        placeholder: "Enter your email",
                        label: "Email",
                    },
                },
                signUp: {

                    username: {
                        placeholder: "Enter your email",
                        label: "Email",
                    },
                },
                resetPassword: {
                    username: {
                        placeholder: "Enter your email",
                        label: "Email",
                    },
                },
            },
        };
    },
    created() {
        console.log("process.env.VUE_APP_SIGN_UP", process.env.VUE_APP_SIGN_UP);
        console.log("showSignUp", this.showSignUp);
        this.setAuthListener();
        if (!localStorage.getItem("showIntro")) {
            localStorage.setItem("showIntro", true);
        }
    },
    methods: {
        setAuthInitialState(value) {
            this.rendered = false;
            this.initialState = value;
            this.$nextTick(() => {
                this.rendered = true;
            });
        },
        async getJwtTokenData() {
            if (this.userLogin && !this.$store.state.JwtToken) {
                await this.$store.dispatch('fetchJwtToken');
            }
        },
        async setAuthListener() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                this.userLogin = true;
                this.$store.user = user;

                // Fetch the current session if the user is authenticated
                const session = await Auth.currentSession();
                this.$store.session = session;
            } catch (err) {
                console.log("User not signed in:", err);
                this.userLogin = false;
                this.$store.user = null;
            }

            // Set up the Hub listener for auth events
            Hub.listen("auth", (data) => {
                const { payload } = data;
                if (payload.event === "signIn") {
                    console.log("User signed in:", payload.data);
                    this.userLogin = true;
                    this.$store.user = payload.data;
                    this.getJwtTokenData();
                }
                if (payload.event === "signOut") {
                    console.log("User signed out");
                    this.userLogin = false;
                    this.$store.user = null;
                }
            });
        },
        showHideLoader() {
            if (this.$store.state.isLoading) {
                this.loadingSpinner = this.$loading.show({
                    color: "#0c244a",
                    loader: "spinner",
                    width: 128,
                    height: 128,
                    backgroundColor: "#ffffff",
                    opacity: 0.5,
                    zIndex: 9999,
                    fullPage: true,
                    class: "loading-spinner-modal",
                });
            } else {
                this.loadingSpinner.hide();
            }
        },
        showToastMessage(message) {
            if (!message) {
                return false;
            }

            if (typeof message === 'string') {
                toast(message, {
                    autoClose: 2000,
                });
            } else if (typeof message === 'object' && message.text) {
                toast(message.text, {
                    autoClose: message.autoClose || 2000,
                    type: message.type || 'default',
                });
            } else {
                console.error("Invalid message format");
            }
        }

    },
    computed: {
        getLoaderInfo() {
            return this.$store.state.isLoading;
        },
        getToastMessage() {
            return this.$store.state.toastMessage;
        },
        isDev() {
            return false;
            // return process.env.VUE_APP_Environment === "dev";
        },
    },
    watch: {
        getLoaderInfo() {
            this.showHideLoader();
        },
        getToastMessage() {
            this.showToastMessage(this.$store.state.toastMessage);
        },
        userLogin: function () {
            this.getJwtTokenData()
        }
    },
};
</script>

<style>
.authentication {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    justify-content: center;
    background-image: url("assets/login-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* Customize the selected button color */
.amplify-button--primary {
    background-color: #5a4fdc !important;
    outline: none !important;
}

.amplify-button--primary:hover {
    background-color: #3c358f !important;
    outline: none !important;
}

.amplify-tabs-item[data-state=active] {
    color: #5a4fdc !important;
    border-color: #5a4fdc !important;
    background-color: var(--amplify-components-tabs-item-active-background-color);
    transition-property: none;
}

.amplify-button--link {
    color: #5a4fdc !important;
}

.federated-sign-in-button:hover {
    background-color: #5a4fdc !important;
    border-color: #5a4fdc !important;
}

.amplify-button:hover .amplify-authenticator__federated-text {
    color: white;
}

.nav-link {
    margin: 0 20px;
}

.navigate-link {
    font-size: 15px;
    color: #5a4fdc !important;
}

.navigate-link:hover {
    text-decoration: underline;
}

[data-amplify-authenticator] [data-amplify-form] {
    padding-bottom: 13px !important;
}

.rovlin-sub-footer-text {
    color: rgb(23, 43, 77);
    cursor: pointer;
    font-size: 13px;
}

.rovlin-sub-footer-text:hover {
    text-decoration: underline;
}

.amplify-tabs {
    display: none !important;
}

/* */
</style>
