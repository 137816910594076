<template>
    <div class="container-fluid main-content-container pb-4">
        <div class="rovlin-prod-page-header row g-0 pt-4">
            <div class="col-12 col-sm-4 text-center text-sm-start mb-0">
                <h3 class="rovlin-prod-page-title">Identity</h3>
            </div>
        </div>

        <IdentityModalComponent :showModal="showModal" @close="closeModal" @refresh="getIdentityEmail"
            @error="handleError" />

        <div class="row g-0 py-4 m-0">
            <div class="col-12 col-md-6 col-lg-8">
                <button type="button" class="btn rounded-4 btn-outline-primary" @click="openModal">
                    Verify A New Email Address
                </button>
            </div>
            <div class="col-12 col-md-6 col-lg-4"></div>
        </div>
        <div class="row g-0 m-0">
            <div class="col custom-new-card p-4">
                <VueGoodTable :rows="tableData" :columns="columns" :sort-options="{ enabled: false }">
                    <template #table-row="{ row, column }">
                        <span v-if="column.field == 'action'">
                            <a>
                                <span class="bi bi-arrow-repeat" @click="refreshEmail(row.email)"></span>&nbsp;&nbsp;
                                <span class="bi bi-trash-fill" @click="deleteEmail(row.email)"></span>
                            </a>
                        </span>
                        <span v-if="column.field == 'resend'">
                            <a v-if="row.status !== 'Success'" class="rovlin-prod-verified-badge">
                                <span class="bi bi-arrow-clockwise"
                                    @click="resendEmail(row.email, row.friendlyName)"></span>
                                Verify
                            </a>
                            <a v-if="row.status === 'Success'" class="rovlin-prod-verified-badge">
                                <span class="bi bi-check-circle"></span>
                                Verified
                            </a>
                        </span>
                    </template>
                </VueGoodTable>
            </div>
        </div>
    </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import APIService from "../../../Services/ApiService/apiService";
import SweetAlert from "../../../Services/sweetAlerts/SweetAlerts";
import IdentityModalComponent from "./IdentityModalComponent.vue";

export default {
    name: "IdentityComponent",
    components: {
        VueGoodTable,
        IdentityModalComponent,
    },
    data() {
        return {
            columns: [
                { label: "Action", field: "action" },
                { label: "Email Address Identities", field: "email" },
                { label: "Verification Status", field: "status" },
                { label: "Business Name", field: "friendlyName" },
                { label: "Status", field: "resend" },
            ],
            tableData: this.$store.state.identities,
            showModal: false,
            isLoading: false,
        };
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        getIdentityEmail() {
            this.isLoading = true;
            APIService.identityManagement()
                .then((res) => {
                    const identityWithIndex = APIService.objectItemWithIndex(res.data);
                    this.$store.state.identities = identityWithIndex;
                    this.tableData = identityWithIndex;
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        deleteEmail(email) {
            SweetAlert.Confirmation({ title: "Are you sure you want to delete this email?" }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteEmailIdentification(email);
                }
            });
        },
        deleteEmailIdentification(email) {
            this.isLoading = true;
            this.$store.state.toastMessage = "";
            APIService.deleteEmailIdentification(email)
                .then(
                    () => {
                        this.getIdentityEmail();
                        this.$store.state.toastMessage = "Email deleted successfully";
                    },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message || errorMessage.response.data;
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                });
        },
        refreshEmail(email) {
            this.isLoading = true;
            this.$store.state.toastMessage = "";
            APIService.refreshEmailIdentification(email)
                .then(() => {
                    this.getIdentityEmail();
                    this.$store.state.toastMessage = "Identities Refreshed";
                })
                .catch((error) => {
                    const errorMessage = { ...error };
                    this.$store.state.toastMessage =
                        errorMessage.response.data.message || errorMessage.response.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        resendEmail(email, friendlyName) {
            const body = { email, friendlyName };
            this.isLoading = true;
            this.$store.state.toastMessage = "";
            APIService.addEmailForVerification(body)
                .then(
                    () => {
                        this.getIdentityEmail();
                        this.$store.state.toastMessage = "Resend email successfully";
                    },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message || errorMessage.response.data;
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleError(error) {
            const errorMessage = { ...error };
            this.$store.state.toastMessage =
                errorMessage.response.data.message || errorMessage.response.data;
        },
    },
    created() {
        const query = this.$router.currentRoute.query;
        if (query && query.email) {
            this.refreshEmail(query.email);
            return;
        }
        this.getIdentityEmail();
    },
};
</script>

<style scoped>
.rovlin-prod-page-title {
    font-weight: 600;
}

.rovlin-prod-verified-badge {
    background-color: #0c244a;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 6px;
    font-style: italic;
    font-weight: 400;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
