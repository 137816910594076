<template>
    <div class="container-fluid pb-4">
        <div class="my-3 p-4 custom-new-card">
            <!-- Header Section: Title and Buttons -->
            <div class="row align-items-center rovlin-prod-page-header mb-3">
                <div class="col-12 col-md-6">
                    <h3 class="rovlin-prod-page-title mb-0">Campaigns</h3>
                </div>
                <div class="col-12 col-md-6 text-md-end mt-3 mt-md-0">
                    <button class="btn btn-primary rounded-4" @click="navigateToCreateUser('new')" style="margin-right: 10px;">
                        Create Campaign
                    </button>
                    <button class="btn btn-primary rounded-4"
                        @click="navigateToDrawflow()">
                        <i class="bi bi-diagram-2-fill"></i>
                    </button>
                </div>
            </div>

            <!-- Filter Section: States Filter -->
            <div class="row rovlin-prod-page-header py-2">
                <div class="col-12 d-flex flex-wrap justify-content-end">
                    <p class="rovlin-prod-text-title mt-1 me-2">Filter by:</p>
                    <button
                        :class="['btn rounded-4 btn-outline-primary me-2 mb-2', { 'active': activeId === this.active }]"
                        @click="getCampaignsByStatus('active')">Active
                    </button>
                    <button
                        :class="['btn rounded-4 btn-outline-primary me-2 mb-2', { 'active': activeId === this.progress }]"
                        @click="getCampaignsByStatus('progress')">Progress
                    </button>
                    <button
                        :class="['btn rounded-4 btn-outline-primary me-2 mb-2', { 'active': activeId === this.pc }]"
                        @click="getCampaignsByStatus('pc')">Partially Completed
                    </button>
                    <button
                        :class="['btn rounded-4 btn-outline-primary me-2 mb-2', { 'active': activeId === this.comp }]"
                        @click="getCampaignsByStatus('comp')">Completed
                    </button>
                    <button
                        :class="['btn rounded-4 btn-outline-primary me-2 mb-2', { 'active': activeId === this.terminated }]"
                        @click="getCampaignsByStatus('terminated')">Terminated
                    </button>
                    <p class="rovlin-prod-clear-all mt-1 ms-2" @click="clearAll()">Clear All</p>
                </div>
            </div>
        </div>

        <!-- List Templates -->
        <div class="custom-new-card p-4 mt-3">
            <VueGoodTable mode="remote" v-on:page-change="onPageChange" v-on:per-page-change="onPerPageChange"
                :totalRows="totalRecords" :pagination-options="{
                        enabled: true,
                        perPageDropdownEnabled: false,
                        setCurrentPage: currentPage,
                    }" :sort-options="{ enabled: false }" :rows="rows" :columns="columns">
                <template #table-row="{ row, formattedRow, column }">
                    <span v-if="column.field == 'before_name'" style="width: 300px;" class="d-flex">
                        <span class="fa bi bi-pencil-fill" @click="navigateToCreateUser(row.id)"
                            style="margin: 3px 0px; letter-spacing: 10px;"></span>
                        <p class="text-truncate"> {{ " " + row.name }}</p>
                    </span>
                    <span v-if="column.field == 'delete'" style="padding: 20px">
                        <span class="fa bi bi-trash-fill" @click="deleteCampaign(row.id, row.c_status)"></span>
                    </span>
                    <span v-if="column.field == 'c_status'" style="padding: 20px;cursor: pointer;">
                        <a @click="openStats(row)">
                            <strong class="text-decoration-underline text-muted fw-fs">{{ formattedRow[column.field]
                        + " "
                                }}</strong>
                        </a>
                        <Popover v-if="row.c_status === 'TERMINATED'" icon="bi-info-circle" :content="row.c_status"
                            title="Campaign Status" />
                    </span>
                    <span v-else>
                        {{ formattedRow[column.field] }}
                    </span>
                </template>
                <template #table-column="props">
                    <div style="display: flex; flex-direction: row">
                        <span>{{ props.column.label }}</span>
                        <span style="display: flex; flex-direction: column; margin-left: 5px">
                            <span
                                class="bi bi-caret-up-fill"
                                v-if="props.column.field === 'before_name'"
                                @click="sort('name', 'asc')"
                                style="font-size: 12px; cursor: pointer; height: 10px;"
                                onmouseover="this.style.color='blue';"
                                onmouseout="this.style.color='#606266';"
                            ></span>
                            <span
                                class="bi bi-caret-down-fill"
                                v-if="props.column.field === 'before_name'"
                                @click="sort('name', 'desc')"
                                style="font-size: 12px; cursor: pointer; height: 10px;"
                                onmouseover="this.style.color='blue';"
                                onmouseout="this.style.color='#606266';"
                            ></span>
                            <span
                                class="bi bi-caret-up-fill"
                                v-if="props.column.field === 's_date'"
                                @click="sort('start_date', 'asc')"
                                style="font-size: 12px; cursor: pointer; height: 10px;"
                                onmouseover="this.style.color='blue';"
                                onmouseout="this.style.color='#606266';"
                            ></span>
                            <span
                                class="bi bi-caret-down-fill"
                                v-if="props.column.field === 's_date'"
                                @click="sort('start_date', 'desc')"
                                style="font-size: 12px; cursor: pointer; height: 10px;"
                                onmouseover="this.style.color='blue';"
                                onmouseout="this.style.color='#606266';"
                            ></span>
                            <span
                                class="bi bi-caret-up-fill"
                                v-if="props.column.field === 'e_date'"
                                @click="sort('end_date', 'asc')"
                                style="font-size: 12px; cursor: pointer; height: 10px;"
                                onmouseover="this.style.color='blue';"
                                onmouseout="this.style.color='#606266';"
                            ></span>
                            <span
                                class="bi bi-caret-down-fill"
                                v-if="props.column.field === 'e_date'"
                                @click="sort('end_date', 'desc')"
                                style="font-size: 12px; cursor: pointer; height: 10px;"
                                onmouseover="this.style.color='blue';"
                                onmouseout="this.style.color='#606266';"
                            ></span>
                            <span
                                class="bi bi-caret-up-fill"
                                v-if="props.column.field === 'c_status'"
                                @click="sort('campaign_status', 'asc')"
                                style="font-size: 12px; cursor: pointer; height: 10px;"
                                onmouseover="this.style.color='blue';"
                                onmouseout="this.style.color='#606266';"
                            ></span>
                            <span
                                class="bi bi-caret-down-fill"
                                v-if="props.column.field === 'c_status'"
                                @click="sort('campaign_status', 'desc')"
                                style="font-size: 12px; cursor: pointer; height: 10px;"
                                onmouseover="this.style.color='blue';"
                                onmouseout="this.style.color='#606266';"
                            ></span>
                        </span>
                    </div>
                </template>
            </VueGoodTable>
        </div>
        <!-- Modal -->
        <button type="button" class="btn rounded-4 btn-primary d-none" data-bs-toggle="modal" ref="showModal"
            data-bs-target="#exampleModal">
            Launch static backdrop modal
        </button>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Vacation Testing Stats</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="closeStats()"></button>
                    </div>
                    <div class="modal-body">
                        <CampaignStats v-if="showStats && campaignStatsDetails"
                            :campaignStatsDetails="campaignStatsDetails" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { VueGoodTable } from "vue-good-table-next";
import APIService from "../../../Services/ApiService/apiService";
import CampaignStats from "./CampaignStats.vue";
import Popover from "@/components/shared/Popper/Popover.vue"
import SweetAlert from "../../../Services/sweetAlerts/SweetAlerts"

export default {
    name: "campaigns-vue",
    components: {
        VueGoodTable,
        CampaignStats,
        Popover
    },
    campaigns: [],
    data() {
        return {
            isLoading: false,
            showStats: false,
            campaignStatsDetails: null,
            totalRecords: 0,
            activeId: 1,
            currentPage: 1,
            active: 1,
            progress: 1,
            pc: 0,
            comp: 0,
            terminated: 0,
            sortBy: "created_at",
            order: "desc",
            columns: [
                {
                    label: "S No.",
                    field: "SNo",
                },
                {
                    label: "Name",
                    field: "before_name",
                },
                {
                    label: "Template",
                    field: "temp_name",
                },
                {
                    label: "Start Date",
                    field: "s_date",
                },
                {
                    label: "End Date",
                    field: "e_date",
                },
                {
                    label: "Status",
                    field: "c_status",
                },
                {
                    label: "Delete",
                    field: "delete",
                },
            ],
            rows: [],
            serverParams: {
                page: 1,
                perPage: 10,
            },
        };
    },
    methods: {
        navigateToCreateUser(id) {
            const query = {
                ...this.$route.query,
                active: this.active,
                progress: this.progress,
                pc: this.pc,
                comp: this.comp,
                terminated: this.terminated,
            };
            this.$router.push({
                name: "addCampaigns",
                params: { id: id },
                query: query
            });
        },
        navigateToDrawflow() {
            this.$router.push({
                name: "campaign-flow",
            });

        },
        getCampaignsByStatus(string) {
            const currentPage = 1;
            this[string] = this[string] === 0 ? 1 : 0;
            const query = {
                ...this.$route.query,
                [string]: this[string],
                page: currentPage,
            };
            this.$router.push({ query });
            this.updateParams({ page: currentPage });
        },

        getCampaigns() {
            this.$store.state.isLoading = true;
            APIService.getCampaigns(
                this.serverParams.page,
                this.active,
                this.progress,
                this.pc,
                this.comp,
                this.terminated,
                this.sortBy,
                this.order
            )
                .then((res) => {
                    this.currentPage = this.serverParams.page;
                    this.$store.state.isLoading = false;
                    this.$store.state.campaigns = res.data.campaign_details;
                    this.totalRecords = res.data.camp_count;
                    let rows = APIService.formateDate(APIService.objectItemIndex(this.$store.state.campaigns, this.serverParams.page - 1), "s_date");
                    rows = APIService.formateDate(rows, "e_date");
                    this.rows = rows;
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    this.$store.state.isLoading = false;
                });
        },
        clearAll() {
            this.active = 0;
            this.progress = 0;
            this.pc = 0;
            this.comp = 0;
            this.terminated = 0;
            const query = {
                ...this.$route.query,
                page: 1,
                active: this.active,
                progress: this.progress,
                pc: this.pc,
                comp: this.comp,
                terminated: this.terminated
            };
            this.$router.push({ query });
            this.updateParams({ page: 1 });
        },

        deleteCampaign(id, status) {
            if (status === "TERMINATED") {
                alert("Terminated campaigns cannot be deleted !");
                return
            }
            SweetAlert.Confirmation({ title: 'Are you sure want to delete this campaigns ?' }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.state.isLoading = true;
                    APIService.deleteCampaigns(id).then(() => {
                        this.$store.state.toastMessage = "Deleted successfully!";
                        this.getCampaigns(this.activeId);
                    })
                        .catch((err) => {
                            const error = { ...err };
                            this.$store.state.toastMessage = error?.response?.data || "Something went wrong!";
                        })
                        .finally(() => {
                            this.$store.state.isLoading = false;
                        });
                }
            });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.isLoading = false;
            this.getCampaigns();
        },

        onPageChange(params) {
            console.log(" => On page changes: ", params);
            const currentPage = params.currentPage;
            const query = {
                ...this.$route.query,
                page: currentPage
            };
            this.$router.push({ query: query });
            this.updateParams({ page: currentPage });
        },
        onPerPageChange(params) {
            const currentPerPage = params.currentPerPage;
            this.$router.push({ query: { ...this.$route.query, perPage: currentPerPage } });
        },

        closeStats() {
            this.showStats = false;
            this.campaignStatsDetails = null;
        },

        openStats(info) {
            console.log("it runs", info)
            this.showStats = true;
            this.campaignStatsDetails = info;
            this.$refs.showModal?.click();
        },

        sort(field, order) {
            const currentPage = 1;
            this.sortBy = field;
            this.order = order;
            const query = {
                ...this.$route.query,
                sortBy: field,
                order: order,
                page: currentPage,
            };
            this.$router.push({ query });
            this.updateParams({ page: currentPage });
        }
    },
    created() {
        const currentPage = parseInt(this.$route.query.page) || 1;
        this.serverParams.page = currentPage;
        console.log("currentPage", currentPage);
        this.active = this.$route.query.active === "0" ? 0 : 1;
        this.progress = this.$route.query.progress === "0" ? 0 : 1;
        this.pc = parseInt(this.$route.query.pc) || 0;
        this.comp = parseInt(this.$route.query.comp) || 0;
        this.terminated = parseInt(this.$route.query.terminated) || 0;
        this.getCampaigns();
    }
};
</script>

<style scoped>
.rovlin-prod-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rovlin-prod-page-title {
    font-weight: 500;
}

.btn {
    padding: 10px 20px;
}

.btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd;
}

.btn-outline-primary.active,
.btn-outline-primary:focus {
    background-color: #0d6efd;
    color: white;
}

.custom-new-card {
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.rovlin-prod-page-title {
    font-weight: 500;
}

span.rovlin-prod-spacer {
    flex: 1 1 auto;
}

.fa {
    cursor: pointer;
}

:deep(label) {
    display: none !important;
}

:deep(.form-group) {
    padding: 10px 0px !important;
}

.btn-outline-light {
    background-color: transparent;
    border-color: #dd6b10;
    color: #dd6b10;
}

.btn-outline-light:hover {
    background-color: rgb(255, 255, 255);
    border-color: #dd6b10;
    color: #dd6b10;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: rgb(253, 253, 253);
    background-color: #dd6b10;
    border-color: #dd6b10;
}

:deep(.btn-outline-light:hover) {
    box-shadow: none !important;
}

.rovlin-prod-clear-all {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    padding: 5px;
    font-size: 14px;
    text-decoration: underline;
    color: #4c6fbb;
    cursor: pointer;
}

.rovlin-prod-text-title {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-left: 5px;
}
.btn-primary,
.btn-outline-primary{
    height: 40px !important;
}
::v-deep .vgt-wrap,
::v-deep .vgt-responsive,
::v-deep .vgt-table th {
    position: static !important;
}

@media (max-width: 770px) {
    .col-12.col-md-6.text-md-end.mt-3.mt-md-0 {
        position: fixed;
        margin-top: 4px !important;
        display: flex;
        justify-content: flex-end;
        right: 45px;
    }
    .ms-md-2 .mt-2 .mt-md-0{
        margin-top: 10px !important;
    }

    .btn {
        margin-right: 10px;
        font-size: 14px;
        font-weight: 300;
        height: 40px;
    }

    .rovlin-prod-text-title {
        margin-right: 5px !important;

    }
}
</style>
