<template>
    <div class="container-fluid main-content-container pb-4">
        <div class="row g-0 rovlin-prod-page-header py-4">
            <h3 class="rovlin-prod-page-title" id="leads-title">Your Leads Group</h3>
            <span class="rovlin-prod-spacer"></span>
            <div v-if="leadsData" class="dropdown" style="width: 162px;">
                <button class="btn btn-primary rounded-4 dropdown-toggle" type="button" id="app_integration_1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    Import with Apps
                </button>
                <ul class="dropdown-menu" aria-labelledby="app_integration_1">
                    <li class="dropdown-item rovlin-prod-cursor-pointer" @click="handleSalesForceIntegration()"
                        v-for="(lead, index) in leadsData" :key="index">
                        {{ lead.name.charAt(0).toUpperCase() + lead.name.slice(1) }}</li>
                </ul>
            </div>
            <button type="button" class=" btn rounded-4 btn-primary rounded-4 mx-1">
                <a href="https://drive.google.com/uc?export=download&id=1SSrCiFo8V-svpefOD03l6NPjSLjxOU50"
                    class="text-white text-decoration-none" target="_blank" download="leads_sample.csv">
                    Download Sample
                </a>
            </button>
            <button class=" btn rounded-4 btn-outline-primary rounded-4 rovlin-prod-file-select mx-1 my-0"
                data-bs-toggle="modal" data-bs-target="#StepperModal" @click="handleOpen()">
                Import Leads
            </button>
            <button class=" btn rounded-4 btn-outline-primary rounded-4 mx-1" @click="navigateToViewAll('viewAll')">
                View All Leads
            </button>
            <button class=" btn rounded-4 btn-outline-primary rounded-4 mx-1" @click="navigateToCreate()">
                Create Leads
            </button>
        </div>
        <div class="row g-0">
            <Information
                info="To bulk import the leads for a campaign, download the sample file above. You may update the same file or import your file with similar format." />
        </div>

        <!-- Modal Starts -->

        <div class="modal fade" id="StepperModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="row w-100">
                            <div class="col-sm-12 col-lg-6 d-flex justify-content-sm-center justify-content-xl-start">
                                <span class="text-center">Add / Import Excel Sheets</span>
                            </div>
                            <div
                                class="col-sm-12 col-lg-6 d-flex justify-content-sm-center align-items-center justify-content-xl-end">
                                <span><i class="bi bi-cloud-arrow-down-fill w-100 h-100 me-2 "
                                        style="font-size: 22px"></i></span>
                                <p class="m-0">Download Excel Standards</p>
                            </div>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            ref="stepperClose" @click="handleClose()"></button>
                    </div>
                    <div class="modal-body p-1">
                        <HorizontalStepper v-if="showModal" :stepComponent="stepComponent" :showModal="showModal"
                            :steps="demoSteps" @send-data="getStepperData" @completed-step="completeStep"
                            @active-step="isStepActive" @stepper-finished="alert" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Starts -->

        <div class="row g-0 rovlin-prod-page-header py-4">

            <div v-if="this.rows.length > 0" class="custom-new-card py-4 px-2 mt-3">
                <VueGoodTable :columns="columns" :rows="rows" :totalRows="totalRecords" :pagination-options="{
                    enabled: true,
                    perPageDropdownEnabled: false,
                    setCurrentPage: currentPage,
                }" :sort-options="{ enabled: false }" v-on:page-change="onPageChange"
                    v-on:per-page-change="onPerPageChange" class="content-align">
                    <template #table-row="props">
                        <span v-if="props.column.field == 'name'">
                            <div class="tooltips top" style="position: relative">
                                <p class="mb-0  text-truncate fw-fs" style="width:227px;">
                                    {{ props.row.name }}
                                </p>
                                <span class="tooltip-text">{{ props.row.name }}</span>
                            </div>
                        </span>
                        <span v-if="props.column.field == 'status'"
                            :class="[{ 'text-success': props.row.status === 'ACTIVE' }]">{{ props.row.status }}</span>
                        <span v-if="props.column.field == 'View'" class="d-flex">
                            <a @click="seeDetails(props.row)" class="text-decoration-underline text-muted fw-fs">See
                                Details</a>
                        </span>
                        <span v-if="props.column.field == 'action'" class="d-flex align-items-center  "
                            style="width: 70px;">
                            <i class="bi bi-pencil-fill me-1 rovlin-prod-icons_info rovlin-prod-cursor-pointer"
                                aria-hidden="true" @click="navigateTo(props.row.id, props.row.name)"></i>
                            <i class="bi bi-trash-fill danger rovlin-prod-icons_info ms-2 rovlin-prod-cursor-pointer"
                                aria-hidden="true" @click="deleteGroup(props.row)"></i>
                        </span>
                    </template>
                </VueGoodTable>
            </div>
            <div class="card no-data-found w-100" v-if="!leadsGroups.length">
                Hey, You don't have any leads. To add leads either you can
                add manually by clicking on Create Leads Button or You can
                download our sample and add your leads and import here.
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import LeadsStepper from "./LeadsStepper.vue";
import HorizontalStepper from "../../shared/Stepper/HorizontalStepper.vue";
import APIService from "../../../Services/ApiService/apiService";
import Information from "../../layouts/sub-components/Information.vue";
import { markRaw } from 'vue'
import utilsService from "../../../Services/utils/utils.service";
import SweetAlert from "../../../Services/sweetAlerts/SweetAlerts"
import { VueGoodTable } from "vue-good-table-next";

export default {
    name: "LeadsComponent",
    components: {
        Information,
        HorizontalStepper,
        VueGoodTable
    },
    data() {
        return {
            leadsData:null,
            leadsGroups: this.$store.state.leadsGroups,
            showModal: false,
            stepComponent: { value: markRaw(LeadsStepper) },
            stepperData: [],
            groupName: null,
            demoSteps: [
                {
                    icon: "bi-file-arrow-up",
                    name: "first",
                    title: "",
                    subtitle: "Add Excel Sheet",
                    completed: false,
                },
                {
                    icon: "bi-diagram-2",
                    name: "second",
                    title: "",
                    subtitle: "Data Mapping",
                    completed: false,
                },
                {
                    icon: "bi-file-earmark-arrow-up-fill",
                    name: "second",
                    title: "",
                    subtitle: "Validate & Import",
                    completed: false,
                },
            ],
            currentPage: 1,
            totalRecords: 0,
            columns: [
                {
                    label: "S No.",
                    field: "SNo",
                },
                {
                    label: "Group",
                    field: "name",
                },
                {
                    label: "Leads Count",
                    field: "leadsCount",
                },
                {
                    label: "Status",
                    field: "status",
                },
                {
                    label: "View",
                    field: "View",
                },
                {
                    label: "Action",
                    field: "action",
                    width: "80px",
                },
            ],
            rows: []
        };
    },
    methods: {
        handleSalesForceIntegration() {
            APIService.importWithSalesForce().then((res) => {
                console.log("we got this response from salesforce", res)
            })
        },
        handleAppsIntegration(app) {
            APIService.importWithApps(app).then((res) => {
                console.log("we got this response from salesforce", res)
            }).catch((error) => {
                console.log({...error})
            })
        },
        // function to get the data from the stepper
        getStepperData(payload) {
            this.$store.state.isLoading = true;
            console.log(" => payload.data: ", payload.data);
            if (payload.data.length < 1) {
                this.$store.state.toastMessage = "No Email Found";
                this.$store.state.isLoading = false;
                this.handleClose();
                return false
            }
            this.stepperData = payload.data
            this.groupName = payload.groupName
            const checkName = utilsService.checkLeadsName(this.groupName, this.leadsGroups);
            if (!checkName) {
                this.$store.state.toastMessage = "Group Name Can't Be same";
                this.$store.state.isLoading = false;
                this.handleClose();
                return false
            }
            this.uploadImportLeads();
        },
        // function to import leads
        async uploadImportLeads() {
            const customers = this.newPostImportData(this.stepperData, this.groupName);
            try {
                for (let i = 0; i < customers.length; i += 50) {
                    const finalCustomers = customers.slice(i, i + 50);
                    const data = [
                        {
                            groupName: this.groupName,
                            customers: finalCustomers,
                        },
                    ];
                    await APIService.createCustomers(data);
                }
                this.$store.state.toastMessage = "Leads Imported Successfully";

            }
            catch (err) {
                this.$store.state.toastMessage = "Something went wrong!"
            } finally {
                this.getLeadsGroup();
                this.$store.state.isLoading = false;
                this.handleClose();
            }
        },
        newPostImportData(stepperData) {
            const finalCustomers = []
            stepperData.map((item) => {
                const finalObject = {
                    firstName: item.Name ? item.Name : null,
                    lastName: item.LastName ? item.LastName : null,
                    whatsappNumber: item.WhatsappNumber ? item.WhatsappNumber : null,
                    messengerId: item.MessengerId ? item.WhatsappNumber : null,
                    email: item.Email,
                    phoneNumber: item.Phone,
                }
                finalCustomers.push(finalObject)
            })

            return finalCustomers
        },
        postImportData(stepperData, groupName) {
            const finalData = [];
            stepperData.map((item) => {
                const finalObject = {
                    groupName: groupName,
                    customers: [
                        {
                            firstName: item.Name ? item.Name : null,
                            lastName: item.LastName ? item.LastName : null,
                            whatsappNumber: item.WhatsappNumber ? item.WhatsappNumber : null,
                            messengerId: item.MessengerId ? item.WhatsappNumber : null,
                            email: item.Email,
                            phoneNumber: "(999) 999-9999",
                        },
                    ],
                }
                finalData.push(finalObject)
            })

            return finalData;
        },
        // Executed when @completed-step event is triggered
        completeStep(payload) {
            this.demoSteps.forEach((step) => {
                if (step.name === payload.name) {
                    step.completed = true;
                }
            })
        },
        // Executed when @active-step event is triggered
        isStepActive(payload) {
            this.demoSteps.forEach((step) => {
                if (step.name === payload.name) {
                    if (step.completed === true) {
                        step.completed = false;
                    }
                }
            })
        },
        // Executed when @stepper-finished event is triggered
        alert() {
            console.log(" => it runs: ",);
            this.$refs.stepperClose.click();
        },
        getLeadsGroup() {
            this.$store.state.isLoading = true;
            APIService.getCustomerGroups().then(res => {
                this.$store.state.isLoading = false;
                this.$store.state.leadsGroups = res.data;
                this.leadsGroups = this.$store.state.leadsGroups;
                this.rows = APIService.itemsWithIndex(this.$store.state.leadsGroups);
            }).catch(() => {
                this.$store.state.toastMessage = "Something went wrong!";
            }).finally(() => {
                this.$store.state.toastMessage = "";
                this.$store.state.isLoading = false;
            });
        },
        handleClose() {
            this.$refs?.stepperClose?.click();
            setTimeout(() => {
                this.showModal = false
            }, 500);
        },
        handleOpen() {
            this.showModal = true;
        },
        seeDetails(item) {
            const currentQuery = this.$route.query;
            console.log("Current Query:", currentQuery);
            this.$router.push({
                name: "leads-group",
                params: { group: item.id, groupName: item.name },
                query: {
                    ...currentQuery,
                    page: 1
                }
            });
        },


        navigateToViewAll() {
            this.$router.push({ name: "viewAll", query: this.$route.query });
        },
        navigateTo(id, name) {
            const currentPage = this.$route.query.leadsGroupPage;
            this.$router.push({ name: "manageGroup", params: { id: id, name: name }, query: { leadsGroupPage: currentPage } });
        },

        navigateToCreate() {
            this.$router.push({ name: "createLeads", query: this.$route.query });
        },
        deleteGroup(item) {
            SweetAlert.Confirmation({ title: 'Are you sure want to delete this group ?' }).then((result) => {
                if (result.isConfirmed && this.$store.user) {
                    this.$store.state.isLoading = true;
                    APIService.deleteCustomerGroups(item.id).then(() => {
                        this.$store.state.toastMessage = "Deleted successfully!";
                        this.$store.state.isLoading = false;
                        this.getLeadsGroup();
                    }).catch(() => {
                        this.$store.state.toastMessage = "Lead Group cannot be Deleted";
                    }).finally(() => {
                        this.$store.state.toastMessage = "";
                        this.$store.state.isLoading = false;
                    });
                }
            });
        },
        async importUserFromFile(e) {
            if (!e.target.files[0]) return;
            this.$store.state.isLoading = true;
            const preAssignedInfo = await this.getPreassignedUrl();
            await this.uploadImageToS3(preAssignedInfo, e.target.files[0]);
            this.$store.state.isLoading = false;
            this.$store.state.toastMessage = "File uploaded successfully!";
            setTimeout(() => {
                this.getLeadsGroup();
            }, 1200);
        },

        getPreassignedUrl() {
            return new Promise((resolve, reject) => {
                try {
                    APIService.getLeadsPreAssignedUrl().then(res => {
                        resolve(res.data);
                    })
                } catch (e) {
                    reject(e);
                }
            })
        },

        uploadImageToS3(preAssUrlInfo, file) {
            return new Promise((resolve, reject) => {
                try {
                    var data = new FormData();
                    data.append('key', preAssUrlInfo.fields['key'])
                    data.append('AWSAccessKeyId', preAssUrlInfo.fields['AWSAccessKeyId'])
                    data.append('x-amz-security-token', preAssUrlInfo.fields['x-amz-security-token'])
                    data.append('policy', preAssUrlInfo.fields['policy'])
                    data.append('signature', preAssUrlInfo.fields['signature'])
                    data.append('file', file)
                    APIService.uploadImageToS3(preAssUrlInfo.url, data).then(res => {
                        resolve(res)
                    })
                } catch (e) {
                    reject(e);
                }
            })
        },

        onPageChange(event) {
            this.$router.push({ name: "leads", query: { leadsGroupPage: event.currentPage } });
            console.log("event", event)
        },
        fetchLeads(){
            APIService.fetchLeadsData().then((res) => {
                this.leadsData = res.data;
                console.log("Fetched leads data:", this.leadsData);
            }).catch(error => {
                console.error("Error fetching leads data:", error);
            });
        }

    },
    mounted() {
        this.fetchLeads();
    },
    created() {
        this.getLeadsGroup();
        const route = useRoute();
        if (route.query && route.query.leadsGroupPage) {
            this.currentPage = parseInt(route.query.leadsGroupPage);
        }
    },

    watch: {
        showModal: function (newValue) {
            console.log(" => showModal: ", newValue);
        }
    }
};
</script>

<style scoped>
.md-with-hover:hover {
    z-index: 2;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.leads_group_card .card-body {
    padding: 0 1.875rem;
    cursor: pointer;
}

.leads-group-name {
    position: relative;
    text-transform: capitalize;
    display: flex;
}

.leads_group_card .rovlin-prod-icons_info {
    display: none;
}

.leads_group_card:hover .rovlin-prod-icons_info {
    display: block;
    cursor: pointer;
}

.danger {
    color: red;
}

.card-deck {
    width: 100%;
    margin: 0;
}

.no-data-found {
    margin: 0;
    padding: 30px 50px;
    text-align: center;
}

.leads-group-action {
    position: absolute;
    display: flex;
    align-items: center;
}

/* Don't forget to hide the original file input! */
.rovlin-prod-file-select>input[type="file"] {
    display: none;
}

.status {
    position: absolute;
    top: 0;
    font-size: 10px;
    left: 0;
    color: white;
    padding: 3px 5px 0 5px;
    border-bottom-right-radius: 5px;
}

.status-active {
    background: #3ac13a;
}

.status-inactive {
    background: lightcoral;
}

.main-upload {
    background-color: #f4f7fd;
    height: 300px;
    border-radius: 4px;
}

.row>.rovlin-prod-page-title,
.row>.rovlin-prod-spacer,
.row>.btn {
    /* reset the width property */
    width: auto;
    max-width: none;
}

.card-deck .card {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
}
::v-deep .vgt-wrap,
::v-deep .vgt-responsive,
::v-deep .vgt-table th {
    position: static !important;
}
</style>
