import Vuex from 'vuex';
import APIService from "../Services/ApiService/apiService";
export default new Vuex.Store({
    state: {
        showProfile: false,
        user: null,
        session: null,
        JwtToken: null,
        isLoading: false,
        TemplateName: "",
        TemplateCategory: "",
        currentUser: null,
        campaigns: [],
        users: [],
        templates: [],
        customers: [],
        leadsGroups: [],
        identities: [],
        mailToUse: [],
        toastMessage: null,
        cardList: [],
        isShowModal: false,
        currentPlanInfo: null,
        transactionHistory: [],
        redirectUrl: null,
        balance:null,
        introSteps: 0, //  intro js  variables
        stepIndex: 0,
        introValue: true,
        searchQuery: ''
    },

    mutations: {
        setPage(state, redirectUrl) {
            state.redirectUrl = redirectUrl;
        },
        setJwtToken(state, token) {
            state.JwtToken = token;
        },
        updateSearchQuery(state, query) {
            state.searchQuery = query;
        }
        // setToastMessage(state, message) {
        //     state.toastMessage = message;
        // },
    },

    actions: {
        async fetchJwtToken({ commit }) {
            const token = await APIService.getJwtToken();
            commit("setJwtToken", token);
        },
    },

    getters: {
        userInfo(state) {
            return state.user;
        },
        jwtToken(state) {
            return state.JwtToken;
        },
    },
});
